
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import DatePicker from '@/components/form/DatePickerIcon.vue';
import FormButton from '@/components/form/Button/index.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormTimePickerRange from '@/components/form/TimePicker/Range.vue';
import FormTimePickerActivatorsRange from '@/components/form/TimePicker/activators/Range.vue';

import ShopShiftsModule from '@/store/shops/shift';
import { strings } from '@/lib/stringConst';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormCheckbox,
    DatePicker,
    ModalBtnBottom,
    FormButton,
    FormTimePickerRange,
    FormTimePickerActivatorsRange,
  },
  emits: ['closeModal'],
})
export default class ShopShiftModalDelete extends Form {
  shopId = '';
  vacancyId = '';
  isTimeInterval = this.model.isTimeInterval.list[0].checked ?? false;
  isExcludeWeekday = true;
  isExcludeWeekends = true;

  get text() {
    return strings;
  }

  get modalParams() {
    return ShopShiftsModule.modalParams;
  }

  get model() {
    return ShopShiftsModule.modalParams.delete.model;
  }

  get interval() {
    const from = `${this.model.intervalStart.hour}:${this.model.intervalStart.minute}`;
    const to = `${this.model.intervalEnd.hour}:${this.model.intervalEnd.minute}`;
    const value = `${from}-${to}`;

    return {
      value,
      range: value.split('-'),
    };
  }

  returnBefore() {
    this.$emit('closeModal');
  }

  updateCheckbox(value: number, params: { name: string; handler: string }) {
    this.$store.dispatch(params.handler, { name: params.name, id: 1, bool: Boolean(value) });
  }

  save() {
    ShopShiftsModule.deleteShiftWarning({
      shopId: this.shopId,
      vacancyId: this.vacancyId,
      handler: 'shopsShifts/deleteShift',
    });
  }

  selectDateStart(date: any) {
    ShopShiftsModule.updateDeleteDateStart(`${date.day}.${date.month}.${date.year}`);
  }

  selectDateEnd(date: any) {
    ShopShiftsModule.updateDeleteDateEnd(`${date.day}.${date.month}.${date.year}`);
  }

  selectTime(value: any) {
    ShopShiftsModule.updateDeleteIntervalStart({ type: 'hour', value: value.from.hour });
    ShopShiftsModule.updateDeleteIntervalStart({ type: 'minute', value: value.from.minute });
    ShopShiftsModule.updateDeleteIntervalEnd({ type: 'hour', value: value.to.hour as any });
    ShopShiftsModule.updateDeleteIntervalEnd({ type: 'minute', value: value.to.minute as any });
  }

  mounted(): void {
    this.shopId = this.$route.params.shopId as string;
    this.vacancyId = this.$route.params.vacancyId as string;
  }
}
