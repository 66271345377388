
import { ref } from 'vue';
import { GuiDatePicker } from '@library/gigant_ui';
import { Component, Watch } from '@/lib/decorator';
import FormButton from '@/components/form/Button/index.vue';
import { CalendarMixin } from '@/components/calendar/calendarMixin';
import IconFont from '@/components/icons/IconFont.vue';
import UserModule from '@/store/user';
import ShopShiftsModule, { PositionSelectData } from '@/store/shops/shift';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import formValidate from '@/lib/formFactory/validate';
import { getShopEmployeesList } from '@/api/shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ShiftEntityInterface } from '@/store/shops/shiftModalParams';
import { SelectItem } from '@/lib/formFactory/select.interface';

library.add(faChevronUp, faChevronDown);

@Component({
  components: {
    FormButton,
    IconFont,
    FormAutocomplete,
    FormSelect,
    GuiDatePicker,
  },
})
export default class ShopShiftCalendar extends CalendarMixin {
  form = ref(null);
  calendarWrapper = ref(null);
  validate = formValidate(this.form);

  employee = { id: null, value: '' };
  employeesItems: { id: string; value: string }[] = [];
  employeesLoading = false;

  blockAddWeek = false;

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get isShopOwner(): boolean {
    return ShopShiftsModule.isShopOwner;
  }

  get canShiftCreate(): boolean {
    return !!this.model.position.list.length && (ShopShiftsModule.isShopOwner || UserModule.isSupervisor);
  }

  get model() {
    return ShopShiftsModule.model;
  }

  get serviceTypeList() {
    return this.model.position.list.filter((serviceType) => serviceType.data.count);
  }

  get currentServiceType() {
    return this.model.position.current;
  }

  set currentServiceType(selectItem: SelectItem<PositionSelectData>) {
    this.model.position.current = selectItem;
  }

  get isBlock() {
    return ShopShiftsModule.isBlock;
  }

  get text() {
    return ShopShiftsModule.text;
  }

  get calendar() {
    return this.$store.getters['shopsShifts/calendarInOrder'];
  }

  get hours() {
    return ShopShiftsModule.hours;
  }

  get calendarStyleByWeeks() {
    return ShopShiftsModule.calendarStyleByWeeks;
  }

  get uniqueShiftIdsSet() {
    return ShopShiftsModule.uniqueShiftIds;
  }

  @Watch('serviceTypeList', { immediate: true })
  async watchOnServiceTypeList() {
    const shopId = this.$route.params.shopId as string;
    const serviceTypeId = this.$route.params.serviceTypeId as string;

    if (!serviceTypeId || !shopId || !this.serviceTypeList.length) {
      return;
    }

    const isExistsServiceType = !!this.serviceTypeList.find((item) => item.id.toString() === serviceTypeId);

    if (!isExistsServiceType) {
      const firstServiceType = this.serviceTypeList[0];
      await this.selectHandler(firstServiceType);
    }
  }

  async selectHandler(selected: SelectItem<PositionSelectData>): Promise<void> {
    await ShopShiftsModule.updatePosition(selected);

    let routerName = 'shop_shift_service_type_id';
    let routerParams: {} = {
      id: this.$route.params.shopId as string,
      serviceTypeId: selected.id.toString(),
    };
    let serviceTypeParams: { shopId: string; serviceTypeId: string } = {
      shopId: this.$route.params.shopId as string,
      serviceTypeId: selected.id.toString(),
    };

    if (this.$route.name === 'customers_shop_shift') {
      routerName = 'customers_shop_shift_service_type_id';
      routerParams = {
        customerId: this.$route.params.customerId as string,
        shopId: this.$route.params.shopId as string,
        serviceTypeId: selected.id,
      };
      serviceTypeParams = {
        shopId: this.$route.params.shopId as string,
        serviceTypeId: this.$route.params.serviceTypeId as string,
      };
    }

    await this.$router.push({ name: routerName, params: routerParams });
    await this.selectToday();

    ShopShiftsModule.initShiftByServiceType(serviceTypeParams);
  }

  addNew(date: string): void {
    if (!this.canShiftCreate) {
      return;
    }

    this.$emit('addShift', date);
  }

  deleteShifts(): void {
    ShopShiftsModule.modalInit({ type: 'delete' });
    ShopShiftsModule.showModal('delete');
  }

  editShift(shift: Partial<ShiftEntityInterface> & { id: string }): void {
    this.$emit('editShift', shift);
  }

  async handleDatePick(date: Date): Promise<void> {
    if (this.blockAddWeek) {
      return;
    }

    try {
      const shopId = this.$route.params.shopId as string;
      this.blockAddWeek = true;

      await ShopShiftsModule.getWeekByDate({ date, shopId });

      if (this.addClass.arrowPrev.length > 0) {
        this.layoutContent.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    } finally {
      this.blockAddWeek = false;
    }
  }

  handlePrev(): void {
    if (this.isBlock) {
      return;
    }

    if (this.addClass.arrowPrev.length > 0) {
      this.layoutContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return;
    }

    if (!this.blockAddWeek) {
      this.blockAddWeek = true;
      ShopShiftsModule.getPrevWeek(this.$route.params.shopId as string).then(() => {
        this.blockAddWeek = false;
      });
    }
  }

  handleNext() {
    if (this.isBlock) {
      return;
    }

    if (this.addClass.arrowNext.length === 0) {
      const limit = Math.max(this.scrollWrapper.scrollHeight, this.scrollWrapper.offsetHeight ?? 0);
      this.layoutContent.scrollTo({
        top: limit,
        behavior: 'smooth',
      });

      return;
    }

    if (!this.blockAddWeek) {
      this.blockAddWeek = true;
      ShopShiftsModule.getNextWeek(this.$route.params.shopId as string).then(() => {
        this.blockAddWeek = false;
        const limit = Math.max(this.scrollWrapper.scrollHeight, this.scrollWrapper.offsetHeight ?? 0);
        this.layoutContent.scrollTo({
          top: limit,
          behavior: 'smooth',
        });
      });
    }
  }

  prepareAutocomplete(employees: { id: string; fullName: string }[]) {
    return employees.map((field: { id: string; fullName: string }) => {
      return {
        id: field.id,
        value: field.fullName,
      };
    });
  }

  async searchEmployees(value: string) {
    try {
      if (value.length === 0) {
        return;
      }

      this.employeesLoading = true;

      const result = await getShopEmployeesList(
        this.$route.params.shopId as string,
        `filters[0][id]=fullName&filters[0][value]=${value}`
      );

      this.employeesItems = this.prepareAutocomplete(result.table.rows);

      this.employeesLoading = false;
    } catch (error) {
      if (error.response.data.errors?.fields) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
      }
    }
  }

  async updateSelectedEmployee(selected: { id: string | null; value: string }) {
    await ShopShiftsModule.updateEmployeeId(selected.id as string);
    ShopShiftsModule.updateShiftsByEmployee(this.$route.params.shopId as string);
  }

  async clearEmployee() {
    this.employee = { id: null, value: '' };

    this.updateSelectedEmployee({ id: null, value: '' });
  }

  getStyle(indexWeek: string, deviation: string, field: 'weekStyle' | 'hourGrid' | 'min' | 'max') {
    const index: string = (parseInt(indexWeek) + parseInt(deviation)).toString();
    if (index in this.calendarStyleByWeeks) {
      return this.calendarStyleByWeeks[index][field];
    }
  }

  mounted(): void {
    const shiftId = this.$route.query.id?.toString();

    if (shiftId) {
      this.editShift({ id: shiftId });
    }
  }
}
